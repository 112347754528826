/**
Custom pdf container
 */
.ng2-pdf-viewer-container {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Input fields */
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
  position: absolute;
  top: -10px;
  left: 10px;
  background: white;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.121333px;
  color: #424152;
}

.mat-form-field.mat-form-field-appearance-fill.fuse-mat-no-subscript .mat-form-field-wrapper {
  border: 1px solid #DEDEE5;
  border-radius: 12px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-no-subscript.mat-focused .mat-form-field-wrapper .mat-form-field-flex
{
  border: 1px solid #1E293B !important;
}

/* Mobile input field*/
.code-box {
  display: flex;
  width: 135px;
  justify-content: space-between;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
}
.code-box-separator {
  display: flex;
  width: 40px;
  justify-content: center;
  font-size: 24px;
  align-items: center;
  height: 92px;
}
.custom-code {
  width: 35px !important;
  height: 35px!important;
  border: 3px solid #777777 !important;
  border-radius: 5px !important;
  -moz-appearance: textfield  !important;
  -webkit-appearance: none !important;
  text-align:center !important;
  font-size: 28px !important;
}

body fuse-splash-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #111827;
  color: #F9FAFB;
  z-index: 999999;
  pointer-events: none;
  opacity: 1;
  visibility: visible;
  transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

body fuse-splash-screen img {
  width: 300px;
  max-width: 300px;
}

body fuse-splash-screen .spinner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  width: 56px;
}

body fuse-splash-screen .spinner > div {
  width: 12px;
  height: 12px;
  background-color: #1E96F7;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: fuse-bouncedelay 1s infinite ease-in-out both;
  animation: fuse-bouncedelay 1s infinite ease-in-out both;
}

body fuse-splash-screen .spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

body fuse-splash-screen .spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes fuse-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes fuse-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

body:not(.fuse-splash-screen-hidden) {
  overflow: hidden;
}

body.fuse-splash-screen-hidden fuse-splash-screen {
  visibility: hidden;
  opacity: 0;
}

.mat-chip-list-wrapper {
  margin: 0px -6px !important;
}

.w-45 {
  width:45px !important;
}
/* Menu */
.example-spacer {
  flex: 1 1 auto;
}
#logo {
  padding-top:10px;
}
.mat-toolbar-single-row {
  padding: 0 10px;
}

/* Header menu*/
.menus {
  margin-top:-20px;
  margin-left: auto !important;
  @media screen and (max-width: 1024px) {
    /*margin-left: auto !important;
    margin-right: auto !important;*/
  }
  button {
    min-height: 40px;
  }
  .menu-item-text {
    position: absolute;
    bottom: -10px;
    font-size: 10px;
    color: var(--fuse-text-secondary);
  }
}
.mat-toolbar.device {
  background: transparent !important;
}
.menus.device {
  background: #1E293B;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20px;
  .menu-item-text {
    font-size: 0.7em !important;
    color: #94a3b8 !important;
  }
  .mat-icon {
    color: #94a3b8 !important;
  }
  .mat-icon-button {
    width: 1em !important;
    height: 1em !important;
  }
}

/*Menu header*/

/* Bottom menu*/


body.light .mat-drawer-container, body .light .mat-drawer-container{
  background-color: #fff !important;
  color: #1e293b;
}

.nuaxia {
  &-page {
    &-title {
      font-family: 'Jost', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 32px;
      color: #141314;
      &-top{
        top: 20px;
        position: relative;
      }
    }
    &-description{
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #1E293B;
    }
  }
  &-jost {
    font-family: 'Jost', serif !important;
  }
  &-lato {
    font-family: 'Lato', serif;
  }
  &-h1 {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 2rem !important;
    line-height: 42px !important;
    color: #141314 !important;
  }
  &-button {
    height: 48px !important;
    background: #1E293B !important;
    border-radius: 12px !important;
  }
  &-button-back .mat-icon {
    color: #1e293b;
    font-size: 42px;
  }
  &-button-text {
    cursor:pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #141314;
    border-bottom: 1px solid;
  }
  &-button-text-link {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    text-align: center !important;
    letter-spacing: 0.121333px !important;
    color: #0C7CFF !important;
  }
  &-login-input {
    border: 1px solid #DEDEE5;
    border-radius: 12px;
  }
  &-menu-header {
    width: inherit;
    .menu-item-text {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.121333px;
      color: #717179;
      mix-blend-mode: normal;
    }
    .menu-item-text.active{
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.121333px;
      color: #141314;
    }
    button{
      height:50px;
    }
    button.active {
      border-bottom:2px solid #141314;
    }
  }
  &-menu-bottom {
    justify-content: center;
    width: 100%;
    @media screen and (max-width: 1024px) {
      /*margin-left: auto !important;
      margin-right: auto !important;*/
    }
    button {
      min-height: 40px;
    }
    .menu-item-text {
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      position: absolute;
      bottom: -10px;
      color: var(--fuse-text-secondary);
    }
  }
  &-bg-card-blue {
    background: #D5E9F0 !important;
    border-radius: 16px;
    cursor:pointer;
  }
  &-bg-card-green {
    background: #C7E3CE !important;
    border-radius: 16px;
    cursor:pointer;
  }
  &-bg-card-yellow {
    background: #FFF0CE !important;
    border-radius: 16px;
    cursor:pointer;
  }
  &-font-gray {
    font-family: 'Lato', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #424152;
  }
  &-card {
    &-description {
      ont-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      /* or 138% */

      letter-spacing: 0.121333px;

      /* Gray */

      color: #717179;
    }
    &-tags {

      &-surveys {
        font-family: 'Lato', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.121333px;
        border: 1px solid #33CCD3;
        color: #33CCD3;
      }
      &-education {
        font-family: 'Lato', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.121333px;
        border: 1px solid #1abc9c;
        color: #1abc9c;
      }
      &-communication {
        font-family: 'Lato', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.121333px;
        border: 1px solid #e67e22;
        color: #e67e22;
      }
    }
    &-rounds {
      &-surveys {
        box-sizing: border-box;
        position: absolute;
        left: 0%;
        right: 97.21%;
        top: 92.41%;
        bottom: 2.53%;
        border: 2px solid #3C5CD3;
      }
      &-education {

      }
      &-communication {

      }
    }
    &-title {
      font-family: 'Jost', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.146667px;
      color: #141314;
    }
  }
  &-popup {
    width:100%;
    display:flex;
    position: relative;
    &-close {
      position: absolute;
      right:0;
    }
    &-title {
      text-align:center;
    }
    &-description {
      text-align:center;
    }
  }
  &-button-a{
    height: 48px !important;
    background: #1E293B !important;
    border-radius: 12px !important;
    color:#fff !important;
  }
  &-error {
    color:red;
  }
}

.intercom-lightweight-app-launcher {
  /*bottom: 60px !important;
  right: 10px !important;*/
}

body.light .mat-snack-bar-container, body .light .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.7);
  background-color: #1E293B !important;
  border-radius: 10px !important;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}
.mat-simple-snackbar {
  justify-content: center !important;
}
.home-icon{
  margin-top:2px;
}

.lh-17 {
  line-height: 17px;
}
