layout {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  /* Base styles for individual layouts */
  > * {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    min-height: 100vh;
  }

  /* Base styles for components that load as a route */
  router-outlet {

    +* {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      width: 100%;
    }
  }
}

/* Ionic  header helper */
@supports (padding-top: 20px) {
  html {
    --ion-safe-area-top: var(--ion-statusbar-padding);
  }
}
@supports (padding-top: constant(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: constant(safe-area-inset-top);
    --ion-safe-area-bottom: constant(safe-area-inset-bottom);
    --ion-safe-area-left: constant(safe-area-inset-left);
    --ion-safe-area-right: constant(safe-area-inset-right);
  }
}
@supports (padding-top: env(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: env(safe-area-inset-top);
    --ion-safe-area-bottom: env(safe-area-inset-bottom);
    --ion-safe-area-left: env(safe-area-inset-left);
    --ion-safe-area-right: env(safe-area-inset-right);
  }
}
